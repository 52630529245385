<script setup lang="ts">
import type {
  UserContentType,
  NovaBoxUserTopHeaderEmits,
  NovaBoxUserTopHeaderProps,
} from './NovaBoxUserTopHeader.types'
import { LayerType, BlockingTarget } from '@store'
import type { TabData } from '@components/NovaTab/NovaTab.types'
import type { HomeActionProps } from '@components/NovaBoxHomeAction/NovaBoxHomeAction.types'

const emit = defineEmits<NovaBoxUserTopHeaderEmits>()
const props = withDefaults(defineProps<NovaBoxUserTopHeaderProps>(), {
  isShowTabMenu: true,
})
const { userStore } = useMembershipProcess()
const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const isOpen = ref(true)

const isEdit = computed(
  () => userStore.user?.userSn === props.homeProfile.userSn
)
const { t } = useI18n()
const state = reactive({
  nameInput: mypageStore.profile?.userNcnm || '',
  textInput: mypageStore.profile?.proflDc || '',
  isNickname: false,
  isText: false,
})
const isEqualUser = computed(
  () => props.homeProfile.userSn === mypageStore.profile?.userSn
)
const homeActionParams = computed(() => {
  const profile = mypageStore.homeProfile
  const params: HomeActionProps | null = profile
    ? {
        target: BlockingTarget.USER,
        userSn: profile.userSn,
        userNcnm: profile.userNcnm,
        isDonation: false,
        blockingAt: profile.blockingAt,
        followerAt: profile.followerAt,
      }
    : null
  return params
})

const onChangeTab = (tabId: TabData<UserContentType>) => {
  emit('onChangeTab', tabId.id)
}

onUnmounted(() => {
  layoutStore['layer:close'](LayerType.USER_PROFILE_EDIT_PANEL)
})
</script>

<template>
  <div :class="['top-section', { off: !isOpen }]">
    <NovaImageContainer
      :key="homeProfile?.backgroundImage"
      :ratio="'5:1'"
      :empty-type="'none'"
      class="top-section-bg"
    />

    <div class="profile-wrap">
      <div class="option-tool">
        <span v-if="!isOpen" class="title">
          {{ t('mypage.profile.title') }}
        </span>
      </div>

      <div v-if="isOpen" class="profile-area">
        <div class="profile-box">
          <NovaPortraitContainer
            class="profile"
            :size="'xl'"
            :stroke="true"
            :image-url="
              isEqualUser
                ? mypageStore.profile?.userProflUrl
                : homeProfile.userProflUrl
            "
          />
        </div>

        <div class="nickname-box">
          <span v-if="!state.isNickname">
            <span class="text">
              {{
                !isEqualUser
                  ? homeProfile.userNcnm
                  : mypageStore.profile?.userNcnm
              }}
            </span>
          </span>
        </div>

        <div class="content-box">
          <span v-if="!state.isText">
            <span class="text">
              {{
                !isEqualUser
                  ? homeProfile.proflDc
                  : mypageStore.profile?.proflDc || '-'
              }}
            </span>
          </span>
        </div>

        <NovaBoxUserUnitSummery
          v-if="!isShowBlockMessage"
          :user-sn="homeProfile.userSn"
          :followings="homeProfile.followingCount"
          :followers="homeProfile.followerCount"
          :coins="isEqualUser ? homeProfile.lmAmount || '0' : undefined"
          :is-equal-user="isEqualUser"
          class="user-summery"
        />

        <NovaBoxUserUnitProfileActions
          v-if="!isShowBlockMessage"
          :follow-flag="homeProfile.followFlag"
          :user-sn="homeProfile.userSn"
          :user-ncnm="homeProfile.userNcnm"
          class="profile-actions"
        />
      </div>
    </div>

    <NovaBoxHomeAction
      v-if="homeActionParams"
      :source="homeActionParams"
      :is-equal-user="isEdit"
      class="home-action"
    />

    <!-- 사용자 차단 안내 -->
    <slot name="blockContents" />

    <!-- 포스트, 리워드, 후원, 내 활동 탭 -->
    <div v-if="isShowTabMenu" class="menu-box">
      <NovaTab
        :tab-value="tabs"
        :init-tab="initTab"
        :align="'center'"
        :horizon-padding="20"
        @on-change-tab="onChangeTab"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-section {
  flex-shrink: 0;
  position: relative;
  padding: 13px 20px 0;
  border-radius: $radius-10;
  overflow: hidden;
  background-color: $color-white;

  :deep(.top-section-bg) {
    &.is-load-success {
      background: inherit;
    }
  }

  .top-section-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 245px;
    border-radius: 16px 16px 0 0;
    background: white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 245px;
      border-radius: 16px 16px 0 0;
    }
    &::after {
      background: hex-to-rgba($color-bg-custom-7, 0);
    }
  }

  &.off {
    &::before,
    &::after {
      height: 50px;
    }

    .profile-wrap {
      max-height: 100px;
    }
    .btn-open {
      transform: rotate(180deg);
    }

    .option-tool {
      justify-content: space-between;
    }
  }
  .btn-options {
    width: 20px;
    height: 20px;
    background-image: url('assets/images/icon-head-options.png');
    background-size: auto 70%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.profile-wrap {
  position: relative;
  z-index: 10;
  @include transition(max-height 0.3s);
}

.option-tool {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-white;

  .title {
    @include text-style($text-heading2-bold);
  }

  .option-button {
    display: flex;
    gap: 8px;
  }

  .btn-open {
    @include transition(transform 0.5s);
  }
}

.profile-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
  padding-top: 145px;
  padding-bottom: 30px;
  color: $color-text-4;
  opacity: 1;
  @include transition(opacity 0.15s ease-in-out);

  .profile-box {
    position: relative;
    padding: 0 12px;

    .btn-box,
    .btn-delete {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .btn-delete {
      color: $color-white;
      top: 0;
      bottom: auto;
    }
    .btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;

      .input-file {
        width: 0;
        height: 0;
        visibility: hidden;
      }
    }
  }

  .profile-control {
    margin-top: 10px;
  }

  .nickname-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 80%;
    .text {
      @include text-style($text-heading1-bold);
      color: $color-text-2;
      word-break: break-all;
    }

    .btn-edit {
      margin-left: 8px;
    }
  }

  .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 80%;

    .text {
      color: $color-text-2;
    }
    .btn-edit {
      margin-left: 8px;
    }
  }

  .edit-box {
    width: 100%;
    .action-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 10px;
    }
  }

  .user-summery {
    margin-top: 24px;
  }

  .profile-actions {
    margin-top: 24px;
  }
}

.home-action {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  z-index: 12;
  border-radius: 10px;
  background: $color-white-80p;
}

.menu-box {
  border-top: 1px solid hex-to-rgba($color-black, 0.06);
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
}

@include mobile {
  .menu-box {
    :deep(.tabs .swiper-wrapper) {
      justify-content: flex-start !important;
    }
  }
}
</style>
